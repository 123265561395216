@import '../../assets/styles/mediaQueriesMixings.scss';
@import '../../assets/styles/colors.scss';

.card {
  width: 100%;
  background: $white;
  box-shadow: 0px 4px 20px rgba(181, 181, 181, 0.2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  min-height: 60px;

  @include sm {
    padding: 0 20px;
  }

  &:hover {
    background-color: $blue-light;
  }
}

.isChecked {
  background-color: $blue-light;
  border: 1px solid $blue-middle;
  box-sizing: border-box;
}