@import '../../assets/styles/mediaQueriesMixings.scss';
@import '../../assets/styles/colors.scss';

.container {
  background-color: $blue-light;
  border-radius: 5px;
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  @include sm {
    flex-direction: column;
  }

  .imageContainer {
    margin-right: 3%;
    @include sm {
      margin-bottom: 5%;
      margin-right: 0;
    }
  }

  .text {
    font-size: 16px;
    line-height: 26px;
    color: $grey-dark;
  }
}