@import '../../assets/styles/colors';
@import '../../assets/styles/mediaQueriesMixings';
@import '../../assets/styles/constants';

$mainInfoOffset: -240px;

.container {
  .photoContainer {
    background-color: $creme;
    height: 740px;

    // Compotability styles with old photos. Delete after uploading all new photos
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .mainInfo {
    padding: 54px 38px 77px;
    width: 630px;
    box-sizing: border-box;
    box-shadow: $default-box-shadow;
    position: absolute;
    background-color: $white;
    left: 50%;
    top: 151px;
    border-radius: 10px;

    @mixin mainInfo-media {
      position: relative;
      left: 0;
      top: $mainInfoOffset;
      width: 700px;
      margin: 0 auto 71px;
      padding: 54px 73px 77px;
    }

    @include lg {
      @include mainInfo-media;
    }

    @include md {
      @include mainInfo-media;
    }

    @include sm {
      width: 100%;
      position: static;
      background-color: transparent;
      box-shadow: none;
      border-radius: none;
      padding: 54px 0 71px;
      overflow: hidden;
    }

    .link {
      font-size: 12px;
      line-height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      text-align: center;
      color: $blue;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid $blue;
      }
    }

    .name {
      font-size: 30px;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }

      @include sm {
        padding: 0 20px;
      }
    }

    .prices {
      margin-top: 10px;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      flex-direction: row;

      & > :not(:first-child) {
        margin-left: 7px;
      }

      .priceCount {
        font-family: 'Geometria-Bold';
      }

      @include sm {
        padding: 0 20px;
      }
    }

    .bages {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex: 1 1 auto;
      margin-top: 12px;

      @include sm {
        justify-content: center;
        padding: 0 20px;
      }

      .badge {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 270px;
        color: $blue-dark;
        line-height: 16px;
        background-color: $blue-light;
        border-radius: 10px;
        text-align: center;
        padding: 12px 10px;
        box-sizing: border-box;

        @include sm {
          font-size: 10px;
          line-height: 24px;
          width: 164px;
          margin-bottom: 28px;

          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }

    .smallPhoto {
      height: 500px;
      margin: 0 auto;
      display: block;
    }

    .points {
      display: flex;
      align-items: flex-start;
      padding: 20px 0;
      border-top: 1px solid $creme;
      margin-top: 38px;

      @include sm {
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 25px;
        border-top: none;
        border-bottom: 1px solid $creme;
        padding: 20px 20px;
      }

      .point {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:not(:last-child) {
          margin-right: 10px;
        }

        .text {
          line-height: 26px;
          padding-left: 8px;
        }

        .circle {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: $green-meant;
        }
      }
    }

    .describe {
      font-size: 16px;
      line-height: 26px;

      @include sm {
        padding: 0 20px;
      }
    }

    .langs {
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;

      @include sm {
        padding: 0 20px;
      }

      .langsBadges {
        display: flex;
        flex-direction: row;
        margin-left: 6px;

        .lang {
          text-transform: uppercase;
          width: 51px;
          height: 32px;
          background-color: $creme;
          margin: 0 3px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
        }
      }
    }

    .actionButtons {
      display: flex;
      justify-content: space-between;
      margin-top: 41px;

      @include sm {
        padding: 0 20px;
        flex-direction: column;
        align-items: center;

        >:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }

  .detailedInfoContainer {
    background-color: $light;
    padding: 90px 0 96px;
    box-sizing: border-box;
    margin-bottom: -130px;

    @include lg {
      padding: 0 0 96px;
    }

    @include md {
      padding: 0 0 96px;
    }

    @include sm {
      padding: 0 0 82px;
      display: flex;
      flex-direction: column;
    }

    .detailed {
      padding: 63px 120px 98px 97px;
      margin: 0 auto;
      box-shadow: $default-box-shadow;
      border-radius: 10px;
      background-color: $white;
      width: 728px;

      .button {
        margin: 0 auto;
        padding-top: 60px;
        display: flex;
        justify-content: center;
      }

      @mixin detailed-media {
        width: 620px;
        margin-top: $mainInfoOffset;
        padding: 63px 40px 82px;
      }

      @include lg {
        @include detailed-media;
      }

      @include md {
        @include detailed-media;
      }

      @include sm {
        flex: 1 1;
        width: auto;
        margin: 0 20px;
        padding: 46px 30px 44px;
      }
      
      .line {
        height: 1px;
        color: $creme;
        border: 0;
        background-color: $creme;
        margin: 40px 0 35px;
        width: 100%;
      }

      .detailedItem {
        .title {
          font-size: 24px;
          line-height: 29px;
        }

        .text {
          margin-top: 17px;
          font-size: 14px;
          line-height: 24px;

          & > h3, h4 {
            margin-top: 25px;
          }

          & > p, ul {
            margin-top: 17px;
          }
        }

        .itemList {
          .listTitle {
            margin-top: 22px;
          }
          .listContainer {
            margin-top: 8px;
            .item {
              margin-top: 14px;
            }
          }
        }
      }
    }
  }
}

.buttonAsk {
  width: 260px;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), $orange;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px $white inset;

  &:not(:disabled) {
    &:hover {
      box-shadow: 0px 5px 20px rgba(245, 161, 88, 0.4);
      background-color: $white;

      span {
        color: $white;
      }
    }
  }

  &:disabled {
    opacity: 0.6;
  }

  @include sm {
    width: 100%;
  }

  span {
    flex: 1 1;
    font-size: 16px;
    line-height: 20px;
    background-image: $orange;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
}

.closeButton {
  position: absolute;
  border: none;
  background: none;
  height: inherit;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:before, &:after {
    position: absolute;
    content: '';
    height: 3px;
    border-radius: 6px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.closeButtonForm {
  width: 19px;
  top: 30px;
  right: 30px;

  &:before, &:after {
    width: 19px;
    height: 2px;
    background-color: $grey-extra-light;
  }
}

.closeButtonSmall {
  top: -30px;
  right: 20px;
}

.closeButtonSmallForm {
  right: 24px;
}
