@import '../../../../assets/styles/mediaQueriesMixings.scss';
@import '../../../../assets/styles/colors.scss';

.info {
  margin-top: 4%;

  @include sm {
    margin-top: 13%;
  }
}

.link {
   color: $blue-middle;
}

.label {
  font-size: 16px !important;
}