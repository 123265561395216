@import '../../../assets/styles/mediaQueriesMixings.scss';
@import '../../../assets/styles/colors.scss';

.howItWorks {
  width: 80%;
  margin: 0 auto;

  @include sm {
    width: 90%;
  }

  .title {
    margin-top: 110px;
    text-align: center;

    @include sm {
      margin-top: 75px;
    }
  }

  .subtitle {
    margin-top: 10px;
    text-align: center;
  }

  .steps {
    margin-top: 64px;
    display: flex;
    position: relative;
    justify-content: space-between;

    @include sm {
      flex-flow: wrap;
      margin-top: 30px;
    }

    .line {
      position: absolute;
      height: 0;
      border-top: 2px dashed $blue-extra-light;
      width: 100%;
      top: calc(57px/2);
      z-index: -1;

      @include sm {
        height: 75%;
        width: 0;
        border-top: 0;
        border-left: 2px dashed $blue-extra-light;
        top: 20px;
        left: 20px;
      }
    }

    .step {
      text-align: center;
      flex-basis: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include sm {
        flex-basis: 100%;
        text-align: left;
        flex-direction: row;
        align-items: baseline;
      }

      .circle {
        width: 57px;
        height: 57px;
        border-radius: 50%;
        background-color: $blue-light;
        display: inline-flex;
        align-items: center; 
        justify-content: center;
        text-align: center;
        color: $blue-middle;
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;

        @include sm {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
          margin-right: 14px;
        }
      }

      .stepInfo {
        .stepTitle {
          margin-top: 19px;
          font-size: 16px;
        }
        .stepSubtitle {
          margin-top: 4px;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}