@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mediaQueriesMixings.scss';

.cta {
  width: 100%;
  background-color: $creme;
  display: flex;
  flex-direction: row;
  margin-top: 130px;
  height: 340px;
  overflow: hidden;
  padding: 0 17%;
  box-sizing: border-box;
  justify-content: space-between;

  @include sm {
    margin-top: 74px;
    flex-direction: column;
    height: auto;
  }

  .info {
    margin-top: 80px;
    max-width: 450px;

    @include sm {
      margin: 61px 5% 0 5%;
      text-align: center;
    }

    .subtitle {
      margin-top: 7px;
      font-size: 16px;
      line-height: 26px;

      @include sm {
        font-size: 14px;
      }
    }

    .buttonContainer {
      width: 280px;

      @include sm {
        width: 100%;
      }

      .button {
        width: 100%;
        margin-top: 30px;
        border: none;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), $orange;

        &:hover {
          box-shadow: 0px 5px 20px rgba(245, 161, 88, 0.4);
        }

        .buttonText {
          color: $white;
          font-size: 1.2em;

          @include sm {
            font-size: 16px;
            line-height: 20px;
          }
        }

        @include sm {
          margin-top: 16px;
        }
      }
    }
  }

  .imageBox {
    height: 100%;
    margin-left: 10%;
    display: flex;
    align-items: flex-end;

    @include sm {
      height: auto;
      width: 90%;
      margin-top: 10%;
      justify-content: center;
    }

    .image {
      height: 100%;
      margin-bottom: -8%;

      @include sm {
        height: auto;
        width: 90%;
      }
    }
  }
}

