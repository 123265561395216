@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';

.container {
  display: flex;
  flex-direction: column;
  background-color: #F6F4F0;

  .inner {
    width: 80%;
    height: calc(100vh - 80px);
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;

    @include lg {
      width: 80%;
      margin: 0 auto;
      padding: 0 10%;
      box-sizing: content-box;
    }
  }

  .imageSection {
    width: 90%;
    margin: 0 auto;

    &:first-of-type {
      padding-top: 80px;
    }

    &:last-of-type {
      margin-bottom: -130px;

      @include sm {
        margin-bottom: -74px;
      }
    }
  }
}

.hero {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .left  {
    display: flex;
    flex-direction: column;
    width: 50%;

    @include md {
      width: 100%;
    }

    @include sm {
      width: 100%;
    }

    h1 {
      color:  #FF8448;
      font-size: 4rem;
      line-height: 1.5;

      @include sm {
        font-size: 2rem;
      }
    }

    .button {
      color: $white;
      font-size: 1rem;
      width: 300px;
      margin-top: 40px;
      border: none;
      background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), $orange;

      &:hover {
        box-shadow: 0 5px 20px rgba(245, 161, 88, 0.4);
      }
    }
  }



  .image {
    width: 50%;

    @include md {
      width: 100%;
    }

    @include sm {
      width: 100%;
    }
  }
}