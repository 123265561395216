@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  margin-bottom: -130px;
}