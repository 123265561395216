@import '../../../../assets/styles/colors.scss';
@import '../../../../assets/styles/mediaQueriesMixings.scss';

.container {
  width: 70%;
  margin-top: -3%;

  @include sm {
    width: 100%;
    margin-top: -6%;
  }

  .message {
    padding-top: 20px;
  }

  .boxOne {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;

    .ageBox {
      flex-basis: 10%;
    }

    .age {
      width: 167px;

      @include sm {
        width: 200px;
      }
    }

    .name, .phone, .connection {
      width: 292px;

      @include sm {
        width: 100%;
      }
    }
  }

  .checkboxContainer {
    user-select: none;
    .box {
      display: block;
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      font-size: 12px;
      line-height: 24px;
      
      @include sm {
        line-height: 20px;
      }

      .errorMessage {
        color: $red;
        font-size: 10px;
        line-height: 13px;
      }

      .privacy {
        color: $blue-middle;
        text-decoration: underline;
      }
    }
    /* Hide the default style of the checkbox */
    input[type=checkbox] {
      visibility: hidden;
    }
    /* Create a custom checkbox */
    .mark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: $white;
      box-sizing: border-box;
      border: 1px solid $grey-extra-light;
      border-radius: 2px;
    }
    /* Specify the background color for the checkbox when it is checked */
    & input:checked + .mark {
      background-color: $blue-middle;
      border: none;
    }
    /* Checkmark to be shown in checkbox */
    /* It will not be shown when not checked */
    .mark:after {
      content: "";
      position: absolute;
      display: none;
    }
    /* Display checkmark when checked */
    & input:checked + .mark:after {
      display: block;
    }
    /* Styling the checkmark using webkit */
    /* Rotated the rectangle by 45 degree and showing only two border to make it look like a tick mark */
    & .mark:after {
      left: 7px;
      bottom: 5px;
      width: 3px;
      height: 9px;
      border: 1px solid #FFFFFF;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  .email {
    width: 100%;
  }

  .subscribe {
    font-size: 12px;
    line-height: 24px;
  }

  .userSexBox {
    @include sm {
      flex-basis: 10%;
    }
    .fields {
      display: flex;
      width: 95px;
      height: 46px;
      border: 1px solid $grey-extra-light;
      box-sizing: border-box;
      border-radius: 5px;

      .line {
        border-left: 1px solid $grey-extra-light;
        margin-left: -2px;
      }

      .checkboxField {
        cursor:pointer;

        input[type="radio"] {
          position: relative;
          top: -1px;
          left: -1px;
          width: 48px;
          height: 46px;
          border-radius: 5px;
          cursor:pointer;
          appearance: none; /*to disable the default appearance of radio button*/
        }

        input[type="radio"]:focus { /*no need, if you don't disable default appearance*/
          outline: none; /*to remove the square border on focus*/
        }

        input[type="radio"]:checked { /*no need, if you don't disable default appearance*/
          background-image: $orange;
        }

        input[type="radio"]:checked ~ span {
          color: white;
        }

        & span {
          position: relative;
          top: -38px;
          left: 16px;
          line-height: 18px;
        }
      }
    }
  }
}

.fieldBox {
  margin-top: 40px;

  @include sm {
    width: 100%;
    margin-top: 35px;
  }
}