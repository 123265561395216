@import '../../assets/styles/mediaQueriesMixings.scss';
@import '../../assets/styles/colors.scss';

.specContainer {
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @include lg {
    width: 80%;
    margin: 0 auto;
    padding: 0 10%;
    box-sizing: content-box;
  }

  .title {
    margin-top: 5%;

    @include sm {
      margin-top: 10%;
    }
  }

  .subTitle {
    margin-top: 1%;

    @include sm {
      margin-top: 5%;
    }
  }

  .linkToAll {
    font-size: 16px;
    line-height: 24px;
    margin: 10% auto;
    text-align: center;
    color: $blue;
    order: 1;
    border-bottom: 1px solid transparent;

    @include md {
      margin: 5% auto;
    }

    @include lg {
      order: unset;
      font-size: 12px;
      line-height: 20px;
      align-self: flex-end;
      margin: 15px 0 0;
    }

    &:hover {
      border-bottom: 1px solid $blue;
    }
  }

  .badgeContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -2% 0;

    @include sm {
      margin: 3% 0 0;
    }

    .badge {
      flex-basis: calc(88%/3);
      margin: 2% 2%;
      width: calc(88%/3);

      .badgeLink {
        width: 100%;
      }

      .linkToAllLess {
        display: none;

        @include lg {
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          display: flex;
          color: $blue;
        }
      }

      @include md {
        flex-basis: calc(92%/2);
        width: calc(92%/2);
      }

      @include sm {
        flex-basis: 100%;
        margin: 10% 0 0;
        width: 100%;
      }
    }
  }

  .specInfo {
    width: 100%;
    background-color: $light;
    border: 1px solid #F0F0F3;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 4% 5%;
    margin: 5% 2%;

    @include sm {
      margin: 10% 0 0;
    }
  }

  .titleInfo {
    text-align: center;
  }

  .textInfo {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include sm {
      flex-direction: column;
    }

    .textBox {
      display: flex;
      flex-basis: 30%;

      @include sm {
        margin-bottom: 5%;
      }

      .icon {
        color: $green-main;
        width: 20px;
        height: 20px;

        @include lg {
          width: 30px;
          height: 30px;
        }
      }

      .text {
        font-size: 16px;
        line-height: 26px;
        margin-left: 10px;
      }
    }
  }
  .button {
    margin: 10% auto 0;
    width: 262px;
    border-color: $blue;
    background-color: $white;

    &:hover {
      background-color: $blue;
    }

    &:hover .buttonText {
      color: $white;
    }

    .buttonText {
      color: $blue;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
