@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';

.buttonsBox {
  margin-top: 17px;
  margin: 17px auto 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @include sm {
    flex-direction: column;
  }

  .button {
    width: 148px;
    height: 40px;
    margin: 0 17px;
    background-color: $white;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;

    &:hover {
      border-color: $blue;
    }

    @include sm {
      width: 100%;
      margin: 4px auto;
    }

    .buttonText {
      color: $grey-dark;
      font-size: 12px;
      line-height: 24px;
    }

    .buttonIcon {
      margin: 0 17px;
      max-width: 24px;

      @include sm {
        margin-left: 35%;
      }
    }
  }
}