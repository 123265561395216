@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';

.footer {
  width: 80%;
  margin: 79px auto 0;
  display: flex;

  @include sm {
    width: 90%;
    margin-top: 59px;
    margin-bottom: 59px;
    flex-direction: column;
  }

  .logo {
    img {
      max-width: 160px;
    }

    & > a {
      width: 100%;
    }

    @include sm {
      width: 50%;
    }
    .mark {
      margin-top: 14px;
      color: $grey-dirty;
      line-height: 24px;
      white-space: nowrap;
    }
  }

  .links {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    margin-left: 6%;
    width: 100%;

    @include sm {
      margin-left: 0;
    }

    .linkItem {
      display: flex;
      flex-direction: column;
      margin-bottom: 5%;

      &:not(:last-child) {
        margin-right: 6%;
      }

      @include sm {
        margin: 30px 0 0;
        flex-basis: 49%;
      }

      .linkTitle {
        font-size: 16px;
        margin-bottom: 2px;
      }

      .linkurl {
        margin-top: 10px;
        font-size: 14px;
        line-height: 24px;
        color: $grey-main;

        @include sm {
          margin-top: 20px;
          font-size: 12px;
          line-height: 11px;
        }
      }
    }
  }
}
