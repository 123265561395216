@import '../../../assets/styles/mediaQueriesMixings.scss';
@import '../../../assets/styles/colors.scss';

.faqs {
  width: 80%;
  margin: 0 auto;

  @include sm {
    width: 90%;
  }

  .header {
    margin-top: 100px;
    text-align: center;

    @include sm {
      margin-top: 80px;
    }

    .subtitle {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .accordion {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @include sm {
      flex-direction: column;
    }

    .acBox {
      margin-bottom: 10px;
      flex-basis: 48%;

      .question {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        background-color: $light;
        border: 1px solid $creme-light;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 0 30px;
        cursor: pointer;

        &:hover .qSign {
          color: $blue;
        }

        .qTitle {
          font-size: 14px;
          line-height: 18px;
          font-family: 'Geometria-Medium';
          color: $grey-dark;
        }
        .qSign {
          font-size: 20px;
          color: $grey-dark;
        }
      }

      .answer {
        display: none;
      }
    }
  }

  .footerText {
    margin-top: 70px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    @include sm {
      margin-top: 40px;
    }
  }

  .buttonsBox {
    margin-top: 17px;
    margin: 17px auto 0;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include sm {
      flex-direction: column;
    }

    .button {
      width: 148px;
      height: 40px;
      margin: 0 17px;
      background-color: $white;
      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      align-items: center;

      &:hover {
        border-color: $blue;
      }

      @include sm {
        width: 100%;
        margin: 4px auto;
      }

      .buttonText {
        color: $grey-dark;
        font-size: 12px;
        line-height: 24px;
      }

      .buttonIcon {
        margin: 0 17px;

        @include sm {
          margin-left: 35%;
        }
      }
    }
  }
}