@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mediaQueriesMixings.scss';

.about {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 0 10%;
  max-width: 2000px;

  @include sm {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }

  .aboutContent {
    width: calc(100%/8 + 100%);
    margin-top: 110px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    @include sm {
      width: 100%;
      flex-direction: column;
      margin-top: 75px;
    }
    .aboutTextContainer {
      flex-basis: 50%;
      .aboutTitle {
        @include sm {
          text-align: center;
          margin-right: 20px;
        }
      }
      .aboutText {
        margin-top: 25px;
        font-size: 16px;
        line-height: 26px;
      }
    }
    .quotesContainer {
      flex-basis: 45%;
      display: flex;
      background-color: $creme;
      font-size: 22px;
      line-height: 34px;
      padding: 30px;
      box-sizing: border-box;

      @include sm {
        width: calc(100% + 20px);
        margin-top: 8%;
        margin-left: 20px;
      }

      .qoutesSymbol {
        margin-right: 30px;
      }

      .quotesText {
        margin-top: 15px;
      }
    }
  }

  .badges {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 74px;

    @include sm {
      margin-top: 59px auto;
    }

    .badge {
      max-width: 220px;
      margin-top: 15px;

      @include sm {
        max-width: 150px;
      }

      .imageContainer {
        width: 100%;
        background-color: $blue-light;
        border-radius: 5px;
        height: 250px;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @include sm {
          max-height: 170px;
        }

        .image {
          width: inherit;
        }
      }

      .info {
        margin-top: 5%; 
        .header {
          font-size: 16px;
          line-height: 24px;
        }
        .subtitle {
          margin-top: 5px;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .buttonContainer {
    margin: 0 auto;
    width: 280px;

    @include sm {
      width: 100%;
    }
    
    .button {
      width: 100%;
      margin: 71px auto 0 auto;
      border: none;
      background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), $orange;

      &:hover {
        box-shadow: 0px 5px 20px rgba(245, 161, 88, 0.4);
      }

      .buttonText {
        color: $white;
        font-size: 1.2em;

        @include sm {
          font-size: 16px;
          line-height: 20px;
        }
      }

      @include sm {
        margin-top: 55px;
      }
    }
  }
}