@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';

.label {
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 10px;

  .labelText {
    margin-left: 4px;
  }

  .big {
    margin-left: 16px;
    font-size: 14px;
    font-family: 'Geometria-Bold';

    @include sm {
      font-size: 12px;
      line-height: 16px;
    }
  }

  input[type="radio"], input[type="checkbox"].likeRadio {
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 14px;
    height: 14px;
    padding: 2px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid $blue-middle;
    border-radius: 50%;
    flex-shrink: 0;
  }

  input[type="radio"]:checked, input[type="checkbox"].likeRadio:checked {
    background-color: $blue-middle;
  }

  input:focus{
    outline:none;
  }

  input[type="radio"].inputBig, input[type="checkbox"].likeRadio.inputBig {
    width: 32px;
    height: 32px;
    padding: 3px;
    border-width: 2px;
  } 
}

.textAreaContainer {
  width: 100%;
  textarea {
    resize: none;
    width: 100%;
    height: auto;
    font-size: 14px;
    line-height: 20px;

    @include sm {
      line-height: 25px;
    }

    &:focus {
      outline: none;
      border-color: $blue-middle;
    }
  }
}

.mainLabel {
  .inputBox {
    display: flex;
    flex-direction: column;

    input[type="text"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="select"]:focus, input[type="input-select"]:focus, input[type="tel"]:focus {
      border: 1px solid $blue-middle;
      outline: none;
    }

    input::placeholder {
      color: $grey-medium-dark;
    }

    .error {
      border-color: $red;
    }
  }
}

.inputSelect {
  &__menu {
  }

  &__item {
    color: black;
    padding: 0 15px;
    &:hover {
      color: white;
      background-color: dodgerblue;
    }
  }
}

.error {
  border-color: $red;
}

.errorMessage {
  visibility: hidden;
  color: $red;
  font-size: 10px;
  line-height: 13px;
  padding: 3px 15px;
  height: 19px;
}

.errorMessageShow {
  visibility: visible;
}
