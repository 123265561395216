@import '../../assets/styles/mediaQueriesMixings.scss';
@import '../../assets/styles/colors.scss';

.container {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logoContainer, .title, .subtitle {
    text-align: center;
  }

  .logoContainer {
    margin: 9% auto 0;
  }

  .logo {
    @include sm {
      width: 120px;
    }
  }

  .title {
    margin-top: 1%;

    @include sm {
      font-size: 28px;
      line-height: 35px;
    }
  }

  .subtitle {
    font-size: 16px;
    line-height: 26px;

    @include sm {
      margin: 11px 20px 0;
    }
  }

  .contact {
    margin-top: 9%;
    font-size: 16px;
    line-height: 24px;

    @include sm {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .contactButtons {
    @include sm {
      width: 100%;
    }
  }

  .buttonContainer {
    width: 280px;

    @include sm {
      width: 90%;
    }

    .button {
      width: 100%;
      margin-top: 9%;
      border: none;
      background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), $orange;

      &:hover {
        box-shadow: 0px 5px 20px rgba(245, 161, 88, 0.4);
      }

      .buttonText {
        color: $white;
        font-size: 16px;
        line-height: 20px;
      }

      @include sm {
        margin-top: 19px;
      }
    }
  }
}

