@import '../../assets/styles/colors';
@import '../../assets/styles/mediaQueriesMixings';
@import '../../assets/styles/constants';

.dot {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 10px;
  padding: 0;
  border: 0;
  background-color: $grey-middle;
}

.activeDot {
  width: 30px;
  border-radius: 100px;
  background-color: $blue-middle;
}
