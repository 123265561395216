@import '../../assets/styles/mediaQueriesMixings';
@import '../../assets/styles/colors';
@import '../../assets/styles/constants';

.container {
  background-color: $white;
  box-shadow: $default-box-shadow;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 10%;
  box-sizing: border-box;

  @include sm {
    height: 64px;
    padding: 0 20px;
  }

  .logoContainer {
    width: 80%;
    display: flex;
    align-items: center;

    .logo {
      display: flex;
      align-items: center;
      
      img {
        width: 100%;
        max-width: 160px;
      }
    }
  }

  .pointsContainer {
    display: flex;
    flex-direction: row;
  }
}