@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';

.gallery {
  display: flex;
  width: 102%;
  flex-flow: row wrap;
  margin: 0 1% 0 -1%;

  @include sm {
    width: 100%;
    margin: 0;
  }

  .itemContainer {
    flex-basis: calc(100%/3 - 2%);
    background-color: $light;
    border-radius: 10px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    padding: 20px;
    margin: 5% 1% 0;

    .itemImage {
      max-width: 100%;
      max-height: 100%;
      background-size: cover;
      background-position: center;
      border-radius: 10px;

      .itemBack {
        margin-top: 100%;
      }
    }

    @include sm {
      flex-basis: 100%;
      margin: 10% 0 0;
    }
  }
}

.carouselImageContainer {
  padding: 45px 0;
  width: 100%;

  .slider {
    width: auto;

    @include lg {
      width: auto;
    }

    .carouselImageItem {
      background-color: $light;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-sizing: border-box;
      padding: 20px;
      margin: 0 10px;
      height: 190px;

      &:nth-child(1) {
        margin-left: 0;
      }
  
      &:nth-child(4) {
        margin-right: 0;
      }

      @include sm {
        margin: 0 5px;
      }

      .itemContainer {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .itemImage {
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
