@import '../../../assets/styles/mediaQueriesMixings.scss';
@import '../../../assets/styles/colors.scss';

.form {
  width: 100%;
  background-color: $light;
  padding-top: 6%;
  padding-bottom: calc(7% + 74px);
  min-height: calc(100vh - 80px);
  box-sizing: border-box;

  @include sm {
    padding-top: 52px;
    padding-bottom: calc(15% + 60px);
    min-height: calc(100vh - 64px);
  }

  .container {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;

    @include lg {
      width: 60%;
      padding: 0;
      margin: 0 auto;
    }

    .name {
      text-align: center;
      line-height: 30px;

      @include sm {
        font-size: 20px;
        line-height: 25px;
      }
    }

    .title {
      margin-top: 5%;

      @include sm {
        margin-top: 7%;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .subtitle {
      font-size: 16px;
      line-height: 26px;

      @include sm {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .formChildren {
      margin-top: 3%;

      @include sm {
        margin-top: 6%;
      }
    }
  }
}