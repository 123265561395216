@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mediaQueriesMixings.scss';

.acBox {
  margin-bottom: 10px;
  flex-basis: 48%;
  position: relative;

  .question {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    background-color: $light;
    border: 1px solid $creme-light;
    border-radius: 5px;
    padding: 10px 30px;
    cursor: pointer;
    box-sizing: border-box;

    &:hover .qSign {
      color: $blue;
    }

    .qTitle {
      font-size: 14px;
      line-height: 18px;
      font-family: 'Geometria-Medium';
      color: $grey-dark;
    }
    .qSign {
      font-size: 20px;
      color: $grey-dark;
      margin-left: 10px;
    }
  }

  .open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 10px;
    border-bottom: 0; 
  }

  .patch {
    position: absolute;
    background-color: $light;
    width: 100%;
    height: 1px;
    border-right: 1px solid $creme-light;
    border-left: 1px solid $creme-light;
    box-sizing: border-box;

    @include sm {
      display: none;
    }
  }

  .answer {
    width: calc(100%/48*100);
    padding: 15px 30px 40px;
    background-color: $light;
    line-height: 26px;
    box-sizing: border-box;
    border: 1px solid $creme-light;
    border-radius: 5px;
    border-top-left-radius: 0;

    @include sm {
      width: 100%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 0;
    }
  }

  &:nth-child(even) .answer{
      margin-left: calc(-52%/48*100);
      border-top-left-radius: 5px;
      border-top-right-radius: 0;

      @include sm {
        margin-left: 0;
      }
    }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }
}