$grey-main: #5E6476;
$grey-dark: #2A2D40;
$grey-light: #D0D1D8;
$grey-dirty: #828282;
$grey-middle: #E0E0E0;
$grey-extra-light: #BDBDBD;
$grey-extra-middle: #E5E5E5;
$grey-medium-dark: #8B8B8B;
$light: #FCFCFE;
$white-light: #DEDEDE;
$blue: #2D9CDB;
$blue-dark: #1D87C2;
$blue-light: #ECF7FD;
$blue-middle: #89CBF1;
$blue-extra-light: #E1F4FE;
$orange: linear-gradient(90.13deg, #FF8448 0.14%, #F3A45A 107.62%);
$orange-light: #FFF5ED;
$red: #EB5757;
$white: #FFFFFF;
$creme: #F6F4F0;
$creme-dark: rgba(245, 160, 88, 0.5);
$creme-light: #ECECF7;
$green-main: #5BBC6D;
$green-meant: #C5F1DB;