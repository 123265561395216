@import '../../../../assets/styles/mediaQueriesMixings.scss';
@import '../../../../assets/styles/colors.scss';

.buttonsFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include sm {
    flex-direction: column;
  }

  .field {
    flex-basis: 30%;
  }
}

.textContainer {
  .title {
    margin-bottom: 3%;

    @include sm {
      font-size: 16px;
      margin-bottom: 5%;
    }
  }
}

.line {
  height: 1px;
  margin: 3% 0 6%;
  color: $grey-middle;
  border: 0;
  background-color: $grey-middle;

  @include sm {
    margin: 13% 0 9%;
  }
}