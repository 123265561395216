@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';

.container {
  border: 1px solid $blue-middle;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(181, 181, 181, 0.2);
  border-radius: 5px;
  margin-top: 20px;

  .header {
    min-height: 60px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    justify-content: space-between;
    border-bottom: 1px dashed $grey-middle;
    cursor: pointer;

    @include sm {
      padding: 0 20px;
    }
    
    .title {
      font-size: 14px;
      color: $grey-main;

      @include sm {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .sign{
      color: $grey-extra-light;
      transition-duration: 0.5s;
      transition-property: transform;
    }

    .signIsOpened {
      transform: rotate(180deg);
    }
  }

  .headerIsOpened {
    border-bottom: 0;
  }

  .children {
    padding: 0 30px 4%;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;

    @include sm {
      padding: 0 20px;
    }
  }

  .childrenIsOpened {
    display: none;
  }

  &:hover .sign {
    color: $blue-middle;
  }
}