@import '../../../../assets/styles/mediaQueriesMixings.scss';
@import '../../../../assets/styles/colors.scss';

.option {
  flex-basis: 49%;
  margin-top: 4%;

  @include sm {
    flex-basis: 100%;
    margin-top: 7%;
  }

  .title {
    font-size: 16px;
  }

  .fields {
    display: flex;
    flex-direction: row;
    margin-left: -10px;

    .field{
      margin-left: 10px;
    }

    .label {
      font-size: 14px;
    }
  }
}