
@import '../../assets/styles/colors';
@import '../../assets/styles/mediaQueriesMixings';
@import '../../assets/styles/constants';

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .image {
    height: 100%;
  }
}
