@import './mediaQueries.scss';
// Small devices

@mixin mobile {
  @media (max-width: #{$screen-mobile}) {
    @content;
  }
}

@mixin sm {
   @media (max-width: #{$screen-tablet}) {
       @content;
   }
}

// Medium devices
@mixin md {
   @media (min-width: #{$screen-tablet}) and (max-width: #{$screen-desktop}) {
       @content;
   }
}

// Large devices
@mixin lg {
   @media (min-width: #{$screen-desktop}) and (max-width: #{$screen-desktop-med - 1}) {
       @content;
   }
}

@mixin lgm {
  @media (min-width: #{$screen-desktop-med}) {
    @content;
  }
}

@mixin rwd($screen) {
   @media (min-width: $screen+'px') {
       @content;
   }
}