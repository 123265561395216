@import '../../assets/styles/mediaQueriesMixings.scss';
@import '../../assets/styles/colors.scss';



.container {
  height: 60vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  text-align: center;
}

.button {
  color: $white;
  font-size: 1rem;
  width: 300px;
  margin-top: 40px;
  border: none;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), $orange;

  &:hover {
    box-shadow: 0 5px 20px rgba(245, 161, 88, 0.4);
  }
}