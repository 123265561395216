@import '../../../assets/styles/colors';
@import '../../../assets/styles/mediaQueriesMixings';
@import '../../../assets/styles/constants';

.closeButtonVideo {
  right: -15px;
  top: -20px;
  position: absolute;
  border: none;
  background: none;
  height: inherit;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:before,
  &:after {
    width: 30px;
    height: 3px;
    background-color: $white;
    position: absolute;
    content: '';
    border-radius: 6px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  @include md {
    right: 20px;
  }

  &.closeButtonSmall {
    top: -30px;
    right: 20px;
  }
}

.bottomContainer {
  background-color: $white;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
}

.showVideoButton {
  width: 260px;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), $orange;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: solid 1px transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px $white inset;

  &:not(:disabled) {
    &:hover {
      box-shadow: 0px 5px 20px rgba(245, 161, 88, 0.4);
      background-color: $white;

      span {
        color: $white;
      }

      svg {
        fill: $white;
      }
    }
  }

  &:disabled {
    opacity: 0.6;
  }

  @include sm {
    width: 100%;
  }

  svg {
    position: absolute;
    left: 24px;
    fill: url(#playGradient);
  }

  span {
    flex: 1 1;
    font-size: 16px;
    line-height: 20px;
    background-image: $orange;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
}
