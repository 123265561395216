@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';

.container {
  width: 100%;
  padding: 70px 20px 5%;
  background-color: $light;
  box-sizing: border-box;

  @include lg {
    padding: 5% 15%;
  }

  .title {
    text-align: center;
    font-size: 24px;
    line-height: 29px;

    @include lg {
      margin-top: 0;
      margin-bottom: 3%;
    }

    @include sm {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .badgeContainer {
    padding: 30px 5%;
    background-color: $white;
    box-shadow: 0px 4px 20px rgba(181, 181, 181, 0.2);
    border: solid 1px transparent;
    border-radius: 10px;
    margin-top: 30px;
    display: flex;

    @include sm {
      padding: 20px;
      display: block;
    }

    .imageContainer {
      @include sm {
        float: left;
        margin-right: 5%;
      }

      .image {
        width: 120px;
        @include sm {
          width: 60px;
        }
      }
    }

    .textContainer {
      margin-left: 7%;

      @include sm {
        margin: 0;
      }

      @include lg {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1 1 auto;
      }

      .contentTitle {
        @include sm {
          padding-left: 27%;
        }
      }

      .contentText {
        margin-top: 2%;
        line-height: 22px;

        @include sm {
          margin-top: 10%;
        }
      }

      .addText {
        font-family: 'Geometria-Italic', Arial, sans-serif;
        font-size: 12px;
        margin-top: 1%;

        @include sm {
          margin-top: 3%;
        }
      }

      .buttonContainer {
        width: 100%;
        margin-top: 4%;

        @include sm {
          margin-top: 7%;
        }

        @include lg {
          margin-top: 0;
          margin-left: 5%;
          width: auto;
        }

        .button {
          width: 220px;
          border: solid 1px transparent;
          background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), $orange;
          background-origin: border-box;
          background-clip: content-box, border-box;
          box-shadow: 2px 1000px 1px $white inset;

          @include sm {
            width: 100%;
          }

          @include lg {
            float: right;
          }

          &:hover {
            box-shadow: none;
          }

          &:hover .buttonText {
            color: $white;
          }

          .buttonText {
            color: transparent;
            background-image: $orange;
            background-clip: text;
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
    }

    &:hover, &:first-of-type {
      background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), $orange;
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 1px 1000px 1px $white inset;
    }

    &:hover .buttonContainer .button, &:first-of-type .buttonContainer .button{
      box-shadow: none;

      .buttonText{
        color: $white
      }
    }
  }
}
