@import '../../../assets/styles/mediaQueriesMixings.scss';
@import '../../../assets/styles/colors.scss';

.checkboxContainer {
  margin-top: 5%;

  @include sm {
    margin-top: 10%;
  }
}

.element {
  flex-basis: 48%;

  @include sm {
    flex-basis: 100%;
  }
}

.elementComment {
  flex-basis: 100%;
}

.loader {
  margin: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text {
    font-size: 16px;
    margin-top: 10%;
  }
}