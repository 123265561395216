@import '../../assets/styles/mediaQueriesMixings.scss';
@import '../../assets/styles/colors.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 80px;
  padding: 0 10%;
  box-shadow: 0px 4px 20px rgba(181, 181, 181, 0.2);
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;

  @include md {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }

  @include sm {
    width: 100%;
    background-color: $white;
    height: 64px;
    box-sizing: border-box;
    padding: 0 20px;
  }

  .logoContainer {
    display: flex;
    align-items: center;

    .logo {
      display: flex;
      align-items: center;
      
      img {
        width: 100%;
        max-width: 160px;
      }
    }
  }

  .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5%;
    height: 100%;

    @include sm {
      // position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100vh;
      margin: 0;
    }

    .links {
      display: flex;
      flex-direction: row;
      font-size: 13px;
      line-height: 16px;
      height: 100%;
      align-items: center;

      @include md {
        display: none;
      }

      @include sm {
        display: none;
      }

      & > :nth-child(n){
        margin-right: 28px;
      };
      & > :last-child {
        margin-right: 0;
      }

      .linkItem {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        cursor: pointer;

        &:hover .linkLine {
          display: block;
        }

        .link {
          display: block;
          color: $grey-main;
        }

        .linkLine {
          height: 2px;
          position: absolute;
          background-image: $orange;
          width: 100%;
          bottom: 0;
          display: none;
        }
      }
    }

    .links.linksIsOpen {
      position: absolute;
      background-color: $white;
      width: 480px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      top: 80px;
      right: 0;
      z-index: 1;
      padding: 0 0 40px 40px;
      box-sizing: border-box;

      @include sm {
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        padding-top: 64px;
        background-color: $creme;
        padding-left: 50px;
      }

      .linkItem {
        font-size: 20px;
        line-height: 25px;
        height: auto;
        margin: 3% 0;

        @include sm {
          margin: 6% 0;
        }
      }
    }

    .button {
      width: 165px;
      height: 44px;
      margin: 0 30px;
      border: solid 1px transparent;
      background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), $orange;
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px $white inset;

      @include sm {
        display: none;
      }

      &:hover {
        box-shadow: none;
      }

      &:hover .buttonText {
        color: $white;
      }

      .buttonText {
        color: transparent;
        background-image: $orange;
        background-clip: text;
        font-size: 12px;
        line-height: 15px;

        @include sm {
          color: $white;
        }
      }
    }

    .button.buttonIsOpen {
      @include sm {
        display: block;
        position: absolute;
        width: calc(100% - 100px);
        z-index: 1;
        bottom: 25%;
        left: 0;
        right: 0;
        margin: 0 50px;
        box-shadow: none;
      }
    }

    .award {
      max-width: 60px;
    }

    .awardLink {
      position: relative;
      @include sm {
        margin-right: 50px;
      }

      .tooltip {
        position: absolute;
        z-index: 100;
        bottom: -50%;
        left: 50%;
        transform: translate(-50%, 10px);
        background: white;
        border-radius: 8px;
        padding: 10px;
        width: 220px;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15), 0 0 5px rgba(0, 0, 0, 0.1);
        visibility: hidden;

        @include sm {
          width: 100px;
          transform: translate(-50%, 40px);
        }

        a {
          color: $blue;
        }
      }

      .tooltip::after {
        content: "";
        position: absolute;
        top:0; /* Position it at the bottom of the tooltip */
        left: 50%; /* Start at the horizontal center of the tooltip */
        transform: translateX(-50%) rotate(45deg); /* Center it and then rotate */
        width: 20px; /* Size of the arrow */
        height: 20px; /* Size of the arrow */
        background: white; /* Match the tooltip background */
        z-index: -2;
      }

      .tooltip.hovered {
        visibility: visible;
      }

    }

    .left {
      margin-left: 50px;
    }

    .button.isShown {
      box-shadow: none;

      .buttonText {
        color: $white;
      }
    }

    .lang {
      // display: flex;
      display: none;
      @include sm {
          display: none;
        }

      & > :nth-child(n){
        text-transform: uppercase;
      }
      & > :nth-child(1){
        color: $grey-main !important;
        margin-right: 6px;

        @include md {
          margin-right: 16px;
        }

        @include sm {
          margin-right: 30px;
        }
      }

      .langLink {
        color: $grey-extra-light;

        @include sm {
          font-size: 20px;
          line-height: 25px;
        }
      }
    }

    .lang.langIsOpen {
      @include sm {
        // display: flex; //return back when language feature will be available
        display: none;
        z-index: 1;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        bottom: 12.5%;
        justify-content: center;
      }
    }
  }

  .menu.menuIsOpened {
    @include sm {
      position: absolute;
    }
  }

  .bars {
    width: 30px;
    height: 20px;
    margin-left: 30px;
    cursor: pointer;
    display: block;
    z-index: 2;
    
    @include lg {
     display: none;
    }
    
    @include lgm {
      display: none;
    }

    @include sm {
      position: absolute;
      top: 22px;
      right: 20px;
    }

    .isClosed {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;

      .bar {
        width: 100%;
        height: 2px;
        background-color: $grey-dirty;
        border-radius: 2px;
      }
    }

    .isOpened {
      position: relative;
      height: inherit;
      width: inherit;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:before, &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        background-color: $grey-dirty;
        border-radius: 2px;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

.container.isOnTop {
  @include sm {
    background-color: $creme;
    box-shadow: none;
  }
}