@import '../../../assets/styles/colors';
@import '../../../assets/styles/mediaQueriesMixings';
@import '../../../assets/styles/constants';

.buttonChoose {
  width: 260px;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), $orange;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;

  &:not(:disabled) {
    &:hover {
      box-shadow: 0px 5px 20px rgba(245, 161, 88, 0.4);
    }
  }

  &:disabled {
    opacity: 0.6;
  }

  @include sm {
    width: 100%;
  }

  svg {
    fill: $white;
    position: absolute;
    width: 21px;
    height: 21px;
    left: 24px;
  }

  span {
    color: $white;
    flex: 1 1;
    font-size: 16px;
    line-height: 20px;
  }
}

.closeButton {
  right: -15px;
  top: -20px;
  position: absolute;
  border: none;
  background: none;
  height: inherit;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:before,
  &:after {
    width: 30px;
    height: 3px;
    background-color: $white;
    position: absolute;
    content: '';
    border-radius: 6px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  @include md {
    right: 20px;
  }

  &.closeButtonSmall {
    top: -30px;
    right: 20px;
  }
}

.closeButtonForm {
  width: 19px;
  top: 30px;
  right: 30px;

  &:before, &:after {
    width: 19px;
    height: 2px;
    background-color: $grey-extra-light;
  }
}

