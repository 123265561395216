@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mediaQueriesMixings.scss';

.box {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 12px;
  line-height: 24px;
  height: 48px;
  
  @include sm {
    line-height: 20px;
  }

  .labeltext {
    a {
      color: $blue-middle;
      text-decoration: underline;
    }
  }

  .errorMessage {
    visibility: hidden;
    color: $red;
    font-size: 10px;
    line-height: 13px;
    height: 19px;
  }

  .errorMessageShow {
    visibility: visible;
  }

  /* Specify the background color for the checkbox when it is checked */
  & input:checked + .mark {
    background-color: $blue-middle;
    border: none;
  }

  /* Display checkmark when checked */
  & input:checked + .mark:after {
    // display: block;
    visibility: visible;
  }
  /* Styling the checkmark using webkit */
  /* Rotated the rectangle by 45 degree and showing only two border to make it look like a tick mark */
  & .mark:after {
    left: 7px;
    bottom: 5px;
    width: 3px;
    height: 9px;
    border: 1px solid #FFFFFF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  /* Hide the default style of the checkbox */
  input[type=checkbox] {
    visibility: hidden;
  }
}

/* Create a custom checkbox */
.mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: $white;
  box-sizing: border-box;
  border: 1px solid $grey-extra-light;
  border-radius: 2px;
}

/* Checkmark to be shown in checkbox */
/* It will not be shown when not checked */
.mark:after {
  content: "";
  position: absolute;
  visibility: hidden;
  // display: none;
}