@import '../../../../assets/styles/colors.scss';
@import '../../../../assets/styles/mediaQueriesMixings.scss';

.text {
  font-size: 16px;
  color: $grey-main;

  @include sm {
    font-size: 12px;
    line-height: 16px;
  }
}

.iconContainer {
  width: 21px;
  height: 17px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  margin-left: 10px;

  .icon {
    width: 100%;
    height: 1px;
    background-color: $grey-dirty;
  }
}

.gifContainer {
  display: flex;
  justify-content: center;
  margin-bottom: -20%;

  @include lg {
    justify-content: flex-end;
    margin-right: -56px;
  }

  img {
    width: 400px;
    height: 100%;

    @include sm {
      width: 80%;
    }
  }
}
