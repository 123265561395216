@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mediaQueriesMixings.scss';

.container {
  width: 100%;
  height: 74px;
  box-shadow: 0px 4px 20px rgba(181, 181, 181, 0.2);
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: rgba(255,255,255, 0.85);

  .buttonsBox {
    width: 100%;
    margin: 0 20px;

    .submitButton, .backButton {
      width: 280px;
      border: none;

      &:hover:disabled {
        box-shadow: none;
      }

      &:disabled {
        opacity: 0.5;
        cursor: auto;
      }

      .buttonText {
        color: $white;
        font-size: 16px;
        line-height: 20px;

        @include sm {
          font-size: 12px;
          line-height: 15px;
        }
      }

      @include sm {
        width: 150px;
        height: 34px;
      }
    }

    .submitButton {
      background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), $orange;
      float: right;

      &:hover {
        box-shadow: 0px 5px 20px rgba(245, 161, 88, 0.4);
      }
    }

    .backButton {
      background-color: $grey-extra-light;
    }

    @include lg {
      width: 70%;
      margin: 0 auto;
    }
  }

  @include sm {
    height: 60px;
  }
}