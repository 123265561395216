@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';

.badgeContainer {
  width: 100%;
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(181, 181, 181, 0.2);
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0px 4px 20px rgba(81, 81, 81, 0.2);
  }

  .imageContainer {
    background-color: $creme;
    width: 100%;
    height: 184px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .badgeShowVideoButton {
      position: relative;
      background-color: $creme;
      width: 100%;
      height: 184px;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      border: none;
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: center;
      padding: 0;
      overflow: hidden;

      .image {
        position: absolute;
        top: 0; //Safari compatibility
      }

      .badgeButtonChooseText {
        padding: 0;
        position: absolute;
        height: 45px;
        width: 45px;
        display: flex;
        top: 5px;
        left: 5px;
      
        svg {
          width: inherit;
          height: inherit;
          fill: $orange;

          path {
            fill: url(#playCircleGradient);
          }
        }
      }

      &:hover .badgeButtonChooseText {
        span {
          background-image: $orange;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;

          &:after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            height: 1px;
            width: 100%;
            background: $orange;
           }
        }

        svg {
          margin-left: 5px;

          circle {
            fill: url(#playCircleGradient);
          }

          path {
            fill: $white;
          }
        }
      }
    }
  }

  .info {
    padding: 0 28px;
    display: flex;
    flex-direction: column;
    flex: 1;

    &:hover .link {
      border-bottom: 1px solid $blue;
    }

    .title {
      margin-top: 25px;
      line-height: 20px;
    }
    .subtitle {
      margin-top: 6px;
      font-size: 16px;
      line-height: 24px;
      color: $grey-main;
      margin-bottom: auto;

      & > span > b {
        font-family: 'Geometria-Bold';
      }
    }

    .line {
      height: 1px;
      margin-top: 12px;
      color: $creme;
      border: 0;
      background-color: #F6F4F0;
    }

    .description {
      margin-top: 15px;
      font-size: 14px;
      line-height: 24px;
      color: $grey-dark;
    }

    .link {
      display: inline-block;
      margin: 13px auto 25px 0;
      font-size: 12px;
      line-height: 20px;
      color: $blue;
      border-bottom: 1px solid transparent;
    }
  }
}