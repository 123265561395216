@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mediaQueriesMixings.scss';

.form {
  margin: 9% 8%;
  .container {
    .photoContainer {
      width: 125px;
      height: 125px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;

      @include sm {
        width: 80px;
        height: 80px;
      }

      img {
        height: 100%;
        transform: translateX(-25%);
      }
    }

    .title, .subtitle, .info {
      text-align: center;
    }

    .title {
      margin-top: 4%;
      line-height: 29px;
    }

    .subtitle {
      margin-top: 3px;
      line-height: 26px;
    }

    .info {
      padding-top: 3px;
    }

    .formChildren {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 5%;

      @include sm {
        flex-direction: column;
      }
    }
  }

  .button {
    width: 262px;
    margin: 4% auto 0;
    display: block;
    border: none;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), $orange;

    &:hover {
      box-shadow: 0px 5px 20px rgba(245, 161, 88, 0.4);
    }

    &:disabled {
      opacity: 0.5;
      cursor: auto;
    }

    @include sm {
      width: 100%;
      margin-top: 10%;
    }

    .buttonText {
      color: $white;
      font-size: 16px;
      line-height: 20px;
    }
  }
}