@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mediaQueriesMixings.scss';

.sos {
  width: 100%;
  background-color: $light;
  margin-top: 96px;
  padding-bottom: 137px;

  @include sm {
    padding: 0 5% 77px;
    margin: 86px auto 0;
    box-sizing: border-box;
  }

  .title {
    padding-top: 100px;
    text-align: center;

    @include sm {
      padding-top: 66px;
    }
  }

  .subtitle {
    margin-top: 10px;
    text-align: center;
  }

  .badges {
    width: 80%;
    display: flex;
    flex-direction: row;
    margin: 46px auto 0;
    justify-content: space-between;

    @include sm {
      width: 100%;
      flex-direction: column;
      margin: 34px 0 0 0;
    }

    .badge {
      background: $white;
      box-shadow: 0px 4px 20px rgba(181, 181, 181, 0.2);
      border-radius: 5px;
      flex-basis: 30%;
      position: relative;
      overflow: hidden;

      @include sm {
        margin-bottom: 14px;
      }

      .label {
        position: absolute;
        width: 16px;
        height: 36%;
        min-height: 58px;
        border-radius: 10px;
        background: linear-gradient(90.02deg, #FF8448 0.14%, #F3A45A 107.62%);
        top: 50%;
        transform: translateY(-50%);
        left: -8px;
      }

      .badgeText {
        padding: 35px;

        .badgeTitle {
          font-size: 20px;
          line-height: 25px;

          .phone {
            color: $grey-dark;
          }
        }

        .badgeSubtitle {
          margin-top: 7px;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}