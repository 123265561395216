@import '../../assets/styles/mediaQueriesMixings.scss';
@import '../../assets/styles/colors.scss';

.accordion {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @include sm {
    flex-direction: column;
  }
}