@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mediaQueriesMixings.scss';

.header {
  background-color: $creme;
  background-size: cover;
  display: flex;
  overflow: hidden;
  padding: 0 10%;

  @include sm {
    padding: 0 20px;
    flex-direction: column;
  }

  .text {
    padding-top: 168px;
    padding-bottom: 195px;

    @include sm {
      padding-top: 74px;
      padding-bottom: 28px;
      width: 100%;
    }

    .title {
      max-width: 600px;
      font-size: 48px;
      line-height: 1.15em;
      margin-bottom: -.15em;

      .titleCtaWrapper{
        display: inline-block;
        position: relative;
        width: 100%;
        .titleCta {
          position: absolute;
          overflow: hidden;
          max-width: 100%;
          height: 2.4em;
          display: inline-block;
          top: 0;
          left: 0;
          color: transparent;
          background-image: $orange;
          background-clip: text;
          opacity: 0;
          transition: opacity 1.2s;
          &.active{
            position: relative;
            transition: opacity 1.2s .2s;
            opacity: 1;
          }
        }
      }

      @include sm {
        font-size: 30px;
        line-height: 1.33em;
      }

      @include lgm {
        .titleCtaWrapper{
          .titleCta {
            max-height: 1.2em;
          }
        }
      }
    }
    .subtitle {
      max-width: 474px;
      font-size: 18px;
      line-height: 30px;

      @include sm {
        margin-top: 11px;
        font-size: 16px;
        line-height: 26px;
      }
    }

    .buttonContainer {
      width: 280px;

      @include sm {
        width: 100%;
      }

      .button {
        width: 100%;
        margin-top: 40px;
        border: none;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), $orange;

        &:hover {
          box-shadow: 0 5px 20px rgba(245, 161, 88, 0.4);
        }

        .buttonText {
          color: $white;
          font-size: 1.2em;

          @include sm {
            font-size: 16px;
            line-height: 20px;
          }
        }

        @include sm {
          margin-top: 24px;
        }
      }
    }
  }

  .imageContainer {
    flex-grow: 1;
    min-width: 400px;

    @include sm {
      height: 274px;
      min-width: 320px;
    }

    //.circle {
    //  position: absolute;
    //  bottom: -102px;
    //  height: 604px;
    //  width: 604px;
    //  background-color: $creme-dark;
    //  border-radius: 50%;
    //  margin-left: auto;
    //  margin-right: auto;
    //  left: 0;
    //  right: 0;
    //
    //  @include sm {
    //    width: 302px;
    //    height: 302px;
    //    top: 56px;
    //  }
    //}

    .image {
      max-width: 1024px;
      margin: 0 auto;
      height: 100%;
      background: url('../../../assets/images/main_image.png') no-repeat center center;
      background-size: contain;

      @include lgm {
        background-size: cover;
        background-position-y: top;
      }

    }
  }
}
