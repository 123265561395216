@import '../../../assets/styles/colors';
@import '../../../assets/styles/mediaQueriesMixings';
@import '../../../assets/styles/constants';

.container {
  background-color: $light;

  .specialists {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 104px 0 40px 0;

    @include sm {
      width: 100%;
      padding: 74px 20px 20px 20px;
      box-sizing: border-box;
    }

    .title {
      text-align: center;
    }

    .tabs {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .button {
        flex-basis: 18%;
        min-width: 200px;
        min-height: 40px;
        border-radius: 100px;
        border: 1px solid $white-light;
        background-color: $white;
        box-sizing: border-box;
        box-shadow: $default-box-shadow;
        margin-top: 16px;
        padding: 10px;
        // cursor: default; //remove when functionality will exist
        &:hover {
          color: orange;
          border: 1px solid orange;
          background-color: $orange-light;
        }
        .buttonText {
          font-family: 'Geometria';
          font-size: 0.9rem;
          $color: $grey-dark;
        }
      }
      .isClicked {
        color: orange;
        border: 1px solid orange;
        background-color: $orange-light;
      }
    }

    .list {
      display: flex;
      flex-direction: column;

      @include sm {
        margin-top: 5%;
      }
      
      .option {
        height: 34px;
        background-color: $white;
      }
    }

    .noExperts {
      font-size: 20px;
      font-weight: 700;
      margin: 25px auto;
    }

    .people {
      margin-top: 75px;
      display: flex;
      flex-direction: column;

      .peopleTitle {
        text-align: center;
      }

      .linkToAll {
        font-size: 16px;
        line-height: 24px;
        margin: 30px auto 0 auto;
        width: auto;
        text-align: center;
        color: $blue;
        order: 1;
        border-bottom: 1px solid transparent;

        @include lg {
          order: unset;
          font-size: 12px;
          line-height: 20px;
          align-self: flex-end;
          margin: 15px 0;
        }

        &:hover {
          border-bottom: 1px solid $blue;
        }
      }

      .profileContainer {
        padding: 45px 0;
        width: 100%;

        @include lg {
          padding-top: 0;
        }

        .dot {
          border-radius: 50%;
          width: 10px;
          height: 10px;
          margin: 0 10px;
          padding: 0;
          border: 0;
          background-color: $grey-middle;
        }

        .activeDot {
          width: 30px;
          border-radius: 100px;
          background-color: $blue-middle;
        }
      }
    }
  }
}
// .customRightArrow {
//   width: 40px;
//   height: 50px;
//   border-radius: 50%;
//   position: absolute;
//   right: 20px;
//   top: 50%;
//   transform: translateY(-50%);
//   padding: 0;
//   margin: 0;
//   border: 0;
//   background: rgba(0,0,0,0.5);

//   &::before {
//     content: '\276F';
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     color: #fff;
//   }
// }

// .customLeftArrow {
//   width: 40px;
//   height: 50px;
//   border-radius: 50%;
//   position: absolute;
//   left: 20px;
//   top: 50%;
//   transform: translateY(-50%);
//   padding: 0;
//   margin: 0;
//   border: 0;
//   background: rgba(0,0,0,0.5);

//   &::before {
//     content: '\276E';
//     position: absolute;
//     right: 50%;
//     top: 50%;
//     transform: translate(50%, -50%);
//     color: #fff;
//   }
// }

// .disableArrow {
//   display: none;
// }
// .carouselButtonGroup {
//   position: absolute;
//   width: 100%;
//   height: 100%;
// }
.profile {
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(181, 181, 181, 0.2);
  margin-right: 1%;

  // @include sm {
  //   margin-right: 1%;
  // }

  .tabsBadge {
    width: 100%;
    height: 100%;
  }

  &.profilePopupOpen {
    transform-style: initial;
  };
}

.slider {
  width: auto;

  & > :nth-child(1) {
    margin-left: 0;
  }

  & > :nth-last-child(1) {
    margin-right: 0;
  }

  @include lg {
    width: auto;
  }

  &.sliderPopupOpen {
    // Rewrite library inline style
    transform: initial !important;
    transform-style: initial;
    will-change: initial;
  }
}